var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "mt-2" },
    [
      _c("rental-address-toolbar", {
        attrs: {
          label: _vm.addressData.label,
          is_verified: _vm.addressData.is_verified
        },
        on: { "toolbar-action": _vm.toolbarAction }
      }),
      _c(
        "v-card-text",
        [
          _c("rental-employment-status-bar", { attrs: { "request-count": 4 } }),
          _c("rental-employment-edit", {
            attrs: {
              title: _vm.addressData.primary_contact.full_address,
              color: "primary",
              data: _vm.addressData,
              "edit-component": "rental-address-edit",
              edit: _vm.edit
            },
            on: {
              "update:edit": function($event) {
                _vm.edit = $event
              }
            }
          }),
          _c(
            "v-slide-x-reverse-transition",
            [
              !_vm.edit
                ? _c("rental-address-summary", {
                    attrs: { items: _vm.summaryItems }
                  })
                : _vm._e()
            ],
            1
          ),
          !_vm.edit
            ? _c("rental-employment-widget", {
                attrs: { widgetData: _vm.addressData }
              })
            : _vm._e()
        ],
        1
      ),
      _c("psi-confirm-dialog", { ref: "confirm" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "940" },
          model: {
            value: _vm.landlordDialog,
            callback: function($$v) {
              _vm.landlordDialog = $$v
            },
            expression: "landlordDialog"
          }
        },
        [
          _vm.landlordDialog
            ? _c("employer-landlord-lookup", {
                attrs: { "contact-data": _vm.addressData },
                on: {
                  close: function($event) {
                    _vm.landlordDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "800" },
          model: {
            value: _vm.noteDialog,
            callback: function($$v) {
              _vm.noteDialog = $$v
            },
            expression: "noteDialog"
          }
        },
        [
          _vm.noteDialog
            ? _c("screening-note", {
                attrs: {
                  order_id: _vm.order_id,
                  types: ["Address"],
                  addresses: [_vm.address]
                },
                on: {
                  close: function($event) {
                    _vm.noteDialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }