<template>
    <v-card class="mt-2">
        <rental-address-toolbar
            :label="addressData.label"
            :is_verified="addressData.is_verified"
            @toolbar-action="toolbarAction"
        ></rental-address-toolbar>
        <v-card-text>
            <rental-employment-status-bar
                :request-count="4"
            ></rental-employment-status-bar>
            <rental-employment-edit
                :title="addressData.primary_contact.full_address"
                color="primary"
                :data="addressData"
                edit-component="rental-address-edit"
                :edit.sync="edit"
            ></rental-employment-edit>

            <v-slide-x-reverse-transition>
                <rental-address-summary
                    :items="summaryItems"
                    v-if="!edit"
                ></rental-address-summary>
            </v-slide-x-reverse-transition>

            <rental-employment-widget
                :widgetData="addressData"
                v-if="!edit"
            ></rental-employment-widget>
        </v-card-text>
        <psi-confirm-dialog ref="confirm"></psi-confirm-dialog>
        <v-dialog v-model="landlordDialog" max-width="940">
            <employer-landlord-lookup
                v-if="landlordDialog"
                :contact-data="addressData"
                @close="landlordDialog = false"
            ></employer-landlord-lookup>
        </v-dialog>
        <!-- Handle the toolbar action of creating a note -->
        <v-dialog v-model="noteDialog" max-width="800">
            <!-- Adding v-if will ensure the component is mounted each time we open the dialog and won't have to reset text area and validation -->
            <screening-note
                v-if="noteDialog"
                :order_id="order_id"
                :types="['Address']"
                :addresses="[address]"
                @close="noteDialog = false"
            >
            </screening-note>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    name: "rental-address",
    components: {
        RentalAddressToolbar: () =>
            import("./Common/RentalEmploymentWidgetToolbar"),
        RentalAddressSummary: () => import("./Common/RentalEmploymentSummary"),
        RentalEmploymentWidget: () => import("./Common/RentalEmploymentWidget"),
        EmployerLandlordLookup: () => import("./Common/EmployerLandlordLookup"),
        ScreeningNote: () => import("./ScreeningProcessNote"),
        RentalEmploymentStatusBar: () =>
            import("./Common/RentalEmploymentStatusBar"),
        RentalEmploymentEdit: () => import("./Common/RentalEmploymentEdit"),
    },
    props: {
        address: {
            type: Object,
            required: true,
        },
        order_id: {
            type: [String, Number],
            required: true,
        },
        summary: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            verify: false,
            edit: false,
            landlordDialog: false,
            noteDialog: false,
            addressData: Object.assign({}, this.address),
        };
    },
    methods: {
        async toolbarAction(action) {
            switch (action) {
                case "landlord_lookup":
                    this.landlordDialog = true;
                    break;
                case "add_note":
                    this.noteDialog = true;
                    break;
                case "remove_address":
                    if (
                        await this.$refs.confirm.open(
                            "Confirm",
                            "Are you sure you want to remove this address? This action cannot be undone.",
                            {
                                headingColor: "primary darken-1",
                                buttonColor: "primary darken-1",
                                icon: "mdi-home-map-marker",
                            }
                        )
                    ) {
                        console.log("Removing address");
                    }
                    break;
            }
        },
    },
    computed: {
        summaryItems() {
            return [
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-home-city",
                    title: this.$_.get(
                        this.addressData,
                        "primary_contact.full_address"
                    ),
                    description: this.$_.get(this.addressData, "description"),
                    enableVerify: true,
                    verified: this.$_.get(this.addressData, "address_verified"),
                },
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-home-export-outline",
                    title: this.$_.get(this.addressData, "date_description"),
                    description: `Moved In: <strong>${this.$_.get(
                        this.addressData,
                        "move_in_date"
                    )}</strong> | Moved Out:<strong>${this.$_.get(
                        this.addressData,
                        "move_out_date"
                    )}</strong>`,
                    enableVerify: true,
                    verified: this.$_.get(
                        this.addressData,
                        "move_dates_verified"
                    ),
                },
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-home-currency-usd",
                    title: `Monthly Payments: ${this.$dollarFormat(
                        this.$_.get(this.addressData, "monthly_payment")
                    )} ${this.$_.get(this.addressData, "payment_type")}`,
                    description: "",
                    enableVerify: true,
                    verified: this.$_.get(
                        this.addressData,
                        "monthly_payment_verified"
                    ),
                },
                {
                    id: this.$uuid.v4(),
                    icon: "mdi-truck-fast",
                    title: `<span class="font-weight-light">Reason for Moving: </span>
                    ${this.$_.get(this.addressData, "reason_for_moving")}`,
                    description: "",
                    enableVerify: false,
                    verified: false,
                },
            ];
        },
    },
};
</script>

<style scoped>
.verify label {
    margin-left: 8px !important;
}
</style>